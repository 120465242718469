import {
  LoadTickets,
  LoadTicketsFail,
  LoadTicketsSuccess,
} from "../../../store";
import { Ticket } from "src/app/models/ticket.model";
import { ChartService } from "./chart.service";
import { SingleValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import moment from "moment";
import { map } from "rxjs";

const STATES = Object.freeze(["Open"]);
const DAYS_LIMIT = 30;
const TYPE = "CARE";

@Injectable()
export class OldOpenCareCasesChartService extends ChartService<SingleValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadTickets,
    failure: LoadTicketsFail,
    success: LoadTicketsSuccess,
  }).pipe(
    map(({ loading, data }) => {
      const dataValue: number =
        data?.filter(
          (s) => this.filterByStatus(s) && this.oldAgeFilter(s.CreatedDate)
        ).length || 0;

      return {
        ngClass: ["chart-text-color--danger"],
        loading,
        data: {
          id: `Open care cases > ${DAYS_LIMIT} days`,
          title: `Open care cases > ${DAYS_LIMIT} days`,
          value: dataValue,
          hyperlink: '/tickets' // need flags
        },
      };
    })
  );

  private oldAgeFilter(inputDate: moment.MomentInput) {
    const currentDate = moment();
    const differenceInDays = currentDate.diff(inputDate, "days");
    return differenceInDays > DAYS_LIMIT;
  }

  private filterByStatus(ticket: Ticket): boolean {
    const ticketType = ticket.Business_Purpose__c?.toUpperCase();
    return STATES.includes(ticket.Status) && ticketType?.includes(TYPE);
  }
}
