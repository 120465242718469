import { Directive, ElementRef, HostListener, Input, Renderer2, TemplateRef, ViewContainerRef, EmbeddedViewRef, OnDestroy } from "@angular/core";

@Directive({
  selector: "[appTooltip]",
})
export class TooltipDirective implements OnDestroy {
  @Input("appTooltip") tooltipTemplateRef?: TemplateRef<any>;

  private tooltipView?: EmbeddedViewRef<any>;
  private tooltipElement?: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef
  ) {}

  @HostListener("mousemove", ["$event"])
  onMouseMove(event: MouseEvent): void {
    if (this.tooltipTemplateRef && this.tooltipElement) {
        const bodyRect = document.body.getBoundingClientRect();
        const OFFSET = 10; 

        const tooltipTop = event.clientY - bodyRect.y + OFFSET;
        const tooltipLeft = event.clientX - bodyRect.x + OFFSET;
    
        this.renderer.setStyle(this.tooltipElement, 'top', `${tooltipTop}px`);
        this.renderer.setStyle(this.tooltipElement, 'left', `${tooltipLeft}px`);
    }
  }

  @HostListener("mouseenter")
  onMouseEnter(): void {
    if (this.tooltipTemplateRef && !this.tooltipView) {
      this.createTooltip();
    }
  }

  @HostListener("mouseleave")
  onMouseLeave(): void {
    this.destroyTooltip();
  }

  private createTooltip(): void {
    if (this.tooltipTemplateRef) {
      // Render the ng-template content
      this.tooltipView = this.viewContainerRef.createEmbeddedView(
        this.tooltipTemplateRef
      );
      this.tooltipElement = this.tooltipView.rootNodes.find(
        (node) => node.nodeType === Node.ELEMENT_NODE
      );

      if (this.tooltipElement) {
        // Apply styles to the tooltip
        this.renderer.setStyle(this.tooltipElement, "position", "absolute");
        this.renderer.setStyle(
          this.tooltipElement,
          "background",
          "rgba(0, 0, 0, 0.7)"
        );
        this.renderer.setStyle(this.tooltipElement, "color", "#fff");
        this.renderer.setStyle(this.tooltipElement, "padding", "5px 10px");
        this.renderer.setStyle(this.tooltipElement, "border-radius", "4px");
        this.renderer.setStyle(this.tooltipElement, "z-index", "1000");
        this.renderer.setStyle(this.tooltipElement, "pointer-events", "none");

        // Append to the body
        this.renderer.appendChild(document.body, this.tooltipElement);
      }
    }
  }

  private destroyTooltip(): void {
    if (this.tooltipView) {
      this.viewContainerRef.clear();
      this.tooltipView = undefined;
      this.tooltipElement = undefined;
    }
  }

  ngOnDestroy(): void {
    this.destroyTooltip();
  }
}
