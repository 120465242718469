import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, effect, inject, OnInit, ViewChild } from '@angular/core';
import { ChartService } from '../services/chart.service';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-horizontal-bars-modal',
  templateUrl: './horizontal-bars-modal.component.html',
  styleUrls: ['./horizontal-bars-modal.component.scss'],
  providers: [ChartService],
})
export class HorizontalBarsModalComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  public readonly dialogRef = inject(MatDialogRef<HorizontalBarsModalComponent>)
  public readonly data = inject(MAT_DIALOG_DATA)

  //#region     BEGIN ChartService
  public readonly chartService$ = inject(ChartService)
  public get data$() { return this.chartService$.data$ }
  public readonly updateChartEffect$ = effect(() => {
    this.chartService$.barChartData$();
    this.chart?.update();
  });
  //#endregion  END ChartService

  ngOnInit(): void {
    this.data$.set(this.data)
  }

}
