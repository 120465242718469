<section>
  <h1>Create <span class="emphasis">Customer Care</span> Ticket</h1>
  <form
    [formGroup]="careFormGroup"
    *ngIf="careFormGroup"
    [ngClass]="{ 'form-submit-attempt': formSubmitAttempted }"
    autocomplete="off"
  >
    <h2>General Information</h2>

    <div class="question">Subject</div>
    <mat-form-field class="custom-textarea">
      <mat-label>Tell us about the issue...</mat-label>
      <textarea matInput formControlName="subject" rows="1"></textarea>
    </mat-form-field>
    <div class="question">Please describe the issue(s) you're experiencing.</div>
    <mat-form-field class="custom-textarea">
      <mat-label>Tell us about the issue...</mat-label>
      <textarea matInput formControlName="description" rows="5"></textarea>
    </mat-form-field>
    <!-- Remove customer ticket number MN1-651 
      <div class="question">Customer Ticket Number</div>
      <mat-form-field>
      <input matInput type="text" formControlName="customerTicketNumber" />
    </mat-form-field> -->
    <div class="form-error" *ngIf="inlineErrors.description">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ inlineErrors.description }}</div>
    </div>

    <app-primary-reporter [formRef]="careFormGroup" [reporters]="primaryReporters" (usersLoaded)="usersLoaded()">
    </app-primary-reporter>

    <app-service-location
      [formRef]="careFormGroup"
      [services]="services"
      [selectedServiceId]="selectedServiceId"
      [inlineErrors]="inlineErrors"
      [validateForNoc]="false"
      (servicesLoaded)="servicesLoaded()"
    >
    </app-service-location>

    <div class="form-error" *ngIf="submissionError && !errorMessage">
      <div class="custom-icon inline_error"></div>
      <div class="error-message"><b>Error: </b> Unable to submit the ticket. Please try again!</div>
    </div>
    <div class="server-error" *ngIf="submissionError && errorMessage">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ errorMessage }}</div>
    </div>

    <div class="loading-indicator" *ngIf="isSubmitting">
      <mat-spinner [diameter]="25"></mat-spinner>
    </div>

    <button
      class="button submit-button"
      (click)="submitTicket()"
      [disabled]="loadingServices || loadingUsers || isSubmitting"
    >
      <span>Submit ticket</span>
    </button>
  </form>
</section>
