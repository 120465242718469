import {
  LoadTickets,
  LoadTicketsFail,
  LoadTicketsSuccess,
} from "../../../store";
import { Ticket } from "src/app/models/ticket.model";
import { ChartService } from "./chart.service";
import { SingleValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";

const STATES = Object.freeze(["Open"]);
const TYPE = "FINANCE";

@Injectable()
export class OpenFinanceCasesChartService extends ChartService<SingleValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadTickets,
    failure: LoadTicketsFail,
    success: LoadTicketsSuccess,
  }).pipe(
    map(({ loading, data }) => {
      const dataValue: number =
        data?.filter((s) => this.filterByStatus(s)).length || 0;

      return {
        ngClass: [],
        loading,
        data: {
          id: "Open finance cases",
          title: "Open finance cases",
          value: dataValue,
          hyperlink: '/tickets' // need flags
        },
      };
    })
  );

  private filterByStatus(ticket: Ticket): boolean {
    const ticketType = ticket.Business_Purpose__c?.toUpperCase();
    return STATES.includes(ticket.Status) && ticketType?.includes(TYPE);
  }
}
