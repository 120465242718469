<!-- eslint-disable import/no-cycle -->
<ng-container>
  <div class="question">Which location?</div>
  <mat-form-field>
    <mat-label>Please select a location</mat-label>
    <input
      type="text"
      placeholder="Please select a location"
      aria-label="Number"
      matInput
      [matAutocomplete]="auto"
      [formControl]="autocompleteLocationControl"
      #input
    />
    <mat-autocomplete
      (optionSelected)="onLocationSelected($event)"
      #auto="matAutocomplete"
      (valueChange)="onLocationSelected($event)"
    >
      <mat-option *ngFor="let l of filteredOptions | async" [value]="l.Z_Location__r.Name" [disabled]="l.disabled">
        {{ l.Z_Location__r.Name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>
