<section class="widget parent-list">
  <div class="scrolling-list">
    <div class="billing-block">
      <div class="billing-status-header">
        <div class="bookends">
          <span class="icon-text">
            <div class="custom-icon billing_dollar">billing_dollar</div>
            <h1>Invoices</h1>
          </span>
          <mat-form-field *ngIf="invoices.length > 5">
            <mat-select [(value)]="invoiceSelectedFilter" (selectionChange)="onInvoiceFilterSelect($event)">
              <mat-option *ngFor="let filter of filters" value="{{ filter.value }}"
                >{{ filter.text }} INVOICES</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="loading-indicator" *ngIf="isInvoicesLoading">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
      <div class="list-scroll">
        <div class="list-empty" *ngIf="invoices.length === 0 && !isInvoicesLoading">
          No invoices have posted to this account yet.
        </div>

        <div
          *ngFor="let invoice of invoices"
          tabindex="0"
          class="list-row"
          [class.selected]="invoice === selectedInvoice"
          (click)="onSelect(invoice)"
          (keyup.enter)="onSelect(invoice)"
        >
          <div class="list-inner">
            <span>
              <label class="date-posted desktop">Date Posted</label>
              <label class="date-posted mobile">Posted</label>
              {{ invoice.ContentDocument.Title.slice(0, 10) | date: 'yyyy-MM-dd' }}
            </span>
            <div class="view-more pdf">
              <button [ngClass]="'download-button' | gainsight: 'billing'" (click)="onDownload(invoice)">
                <div class="custom-icon pdf"></div>
              </button>
            </div>
            <div class="custom-icon arrow_progress"></div>
            <div (click)="onDownload(invoice)" [ngClass]="'invoice-link mobile' | gainsight: 'billing'"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="billing-block">
      <div class="billing-status-header payments-header">
        <div class="bookends">
          <span class="icon-text">
            <div class="custom-icon billing_dollar">billing_dollar</div>
            <h1>Payments</h1>
          </span>
          <mat-form-field *ngIf="payments.length > 5">
            <mat-select [(value)]="paymentSelectedFilter" (selectionChange)="onPaymentFilterSelect($event)">
              <mat-option *ngFor="let filter of filters" value="{{ filter.value }}" class="billing-option">
                {{ filter.text }} PAYMENTS</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="loading-indicator" *ngIf="isPaymentsLoading">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
        <div class="list-scroll">
        <div class="list-empty" *ngIf="payments.length === 0 && !isPaymentsLoading">
          No payments have posted from this account yet.
        </div>
        <ng-container *ngIf="payments.length > 0 && payments.length !== 0; else noFilteredPayments">
          <div *ngFor="let payment of payments" tabindex="0" class="list-row payment-row">
            <div class="list-inner payment">
              <span>
                <label class="date-posted desktop">Date Posted</label>
                <label class="date-posted mobile">Posted</label>
                {{ payment.Transaction_Date__c | date: 'yyyy-MM-dd' }}
              </span>

              <div class="amount">{{ payment.Transaction_Amount__c | positiveNumber | currency: 'USD' }}</div>
            </div>
          </div>
        </ng-container>
        <ng-template #noFilteredPayments>
          <div class="list-empty" *ngIf="payments.length !== 0">
            There are no payments available based on the filter selection.
          </div>
        </ng-template>
      </div>
    </div>
    <app-help-cta *ngIf="browserSize === 'small'" [originPage]="'Billing'"></app-help-cta>
  </div>
  <app-footer></app-footer>
</section>
