import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderPaginatorComponent } from './order-paginator.component';

import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

import * as Orders from '../models';
import OrderRow from './order-row.interface';

@Component({
  selector: 'app-table-orders',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MatPaginatorIntl, useClass: OrderPaginatorComponent }],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class OrderTableComponent implements OnInit, OnChanges {
  @Input() data: OrderRow[] = [];
  @Input() loading: boolean = true;
  @Input() filterService: any[];
  @Input() filterStatus: any[];
  @Input() filterAddress: any[];
  @Output() filterRemove: EventEmitter<any> = new EventEmitter();
  @Output() selectOrder: EventEmitter<Orders.Order> = new EventEmitter();
  @ViewChild(MatTable) table: MatTable<OrderRow>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('paginatorElement', { read: ElementRef }) paginatorHtmlElement: ElementRef;
  @ViewChild(MatSort) sort: MatSort;

  public orderColumns: string[] = [
    //		'id',
    //		'oli',
    'nit',
    'deal',
    //		'site',
    'address',
    'city',
    'state',
    'service',
    'line_type',
    'status',
    'foc_date',
    'expands'
  ];
  public allExpanded: boolean = false;
  public dataSource: MatTableDataSource<OrderRow>;
  public pageSizeOptions: any[];
  public csvDownloadFileName: string = 'Orders.csv';
  public query: string = '';
  public searchLoading: boolean = false;
  private searchSubject: Subject<string> = new Subject();
  private firstRun: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.loadDataSource();
    this.startSearchFn();
    //		this.checkUrlData();
  }

  ngOnChanges(changes) {
    if (changes.filterStatus) {
      this.filterStatusClear();
    }
    if (this.firstRun) {
      this.firstRun = false;
      return;
    }
    this.refresh();
  }

  private filterStatusClear(): void {
    this.filterStatus = this.filterStatus.filter((st) => st.value >= 0);
  }

  public removeThis(type, item) {
    this.filterRemove.emit({ type, item });
  }

  private startSearchFn() {
    this.searchSubject.pipe(debounceTime(500)).subscribe((query) => this.searchData(query));
  }
  private searchData(query) {
    if (!query) return this.loadDataSource();
    query = query.toLowerCase();
    let dataS: OrderRow[] = [];
    dataS = this.data.filter((row) => {
      return (
        row.nit.toLowerCase().includes(query) ||
        row.deal?.toLowerCase().includes(query) ||
        row.site?.toLowerCase().includes(query) ||
        row.address?.toLowerCase().includes(query) ||
        row.city?.toLowerCase().includes(query) ||
        row.state?.toLowerCase().includes(query) ||
        row.service?.toLowerCase().includes(query) ||
        row.line_type?.toLowerCase().includes(query) ||
        row.status?.toLowerCase().includes(query) ||
        row.foc_date?.toLowerCase().includes(query)
      );
    });
    this.loadDataSource(dataS);
  }
  public triggerSearch() {
    this.searchLoading = true;
    this.searchSubject.next(this.query);
  }

  public showingPage() {
    let pageSize = this.paginator?.pageSize;
    let pageLabel = pageSize == 99999 ? 'All' : pageSize;
    this.paginatorHtmlElement.nativeElement.querySelector(
      '.mat-paginator-page-size .mat-select-value > span > span'
    ).innerHTML = pageLabel;
  }

  public toggleExpandAll(): void {
    this.allExpanded = !this.allExpanded;
    this.data.map((r) => (r.expanded = this.allExpanded));
    this.loadDataSource();
  }

  private startPageSizeOptions() {
    if (!this.data) return;
    const max = this.data.length;
    this.pageSizeOptions = [];
    if (max < 50) {
      this.pageSizeOptions.push(10);
    }
    this.pageSizeOptions.push(50);
    if (max > 100) {
      this.pageSizeOptions.push(100);
    }
    this.pageSizeOptions.push(99999);
  }


  private checkUrlData() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['query']) {
        this.query = params['query'];
        this.triggerSearch();
      }
    });
  }
  private updateUrl() {
    let params = {};
    if (this.query) params['query'] = this.query;
    if (this.filterService.length > 0) params['service'] = this.filterService.map((s) => s.value).join(',');
    if (this.filterStatus.length > 0) params['status'] = this.filterStatus.map((s) => s.value).join(',');
    if (this.filterAddress.length > 0) params['location'] = this.filterAddress.join('|');
    this.router.navigate(['/orders'], {
      queryParams: params,
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
  }
  private updateUrlToOrder(orderId: string) {
    this.router.navigate(['/orders/' + orderId + '/view'], {
      queryParams: {},
      replaceUrl: true
    });
  }

  private loadDataSource(useData?: OrderRow[]) {
    
    this.startPageSizeOptions();
    if (!useData) useData = this.data;
    this.dataSource = new MatTableDataSource<OrderRow>(useData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.table) this.table.renderRows();
    this.searchLoading = false;
  }

  public refresh(): void {
    this.loadDataSource();
    if (!this.table) return;
    this.table.renderRows();
  }

  public openOrder(o: Orders.Order): void {
    //		this.updateUrlToOrder(o.id);
    this.selectOrder.emit(o);
  }

  public getOrderCsvData(): Orders.OrderCsvDataRow[] {
    if (!this.data) {
      return [];
    }

    return Orders.getOrderCSVData(this.data);
  }
}
