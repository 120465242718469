import {
  Inject,
  NgModule,
} from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPermissionsModule } from "ngx-permissions";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from "ngx-moment"; // optional, provides moment-style pipes for date formatting

// ngrx
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { NgxPaginationModule } from "ngx-pagination";

// components
import { BillingService } from "src/app/services/billing.service";
import { BrowserService } from "src/app/services/browser.service";
import { HeaderService } from "src/app/services/header.service";
import { TicketService } from "src/app/services/ticket.service";
import { CommentService } from "src/app/services/comment.service";
import { appReducer, effects } from "src/app/store";
import { AppComponent } from "./app.component";
import { NgChartsModule } from 'ng2-charts';
import { AppLayoutComponent } from "./_layout/app-layout/app-layout.component";
import { PageNotFoundComponent } from "./core/page-not-found/page-not-found.component";
import { PublicLayoutComponent } from "./_layout/public-layout/public-layout.component";
import { NocPolicyComponent } from "./noc-policy/noc-policy.component";

// modules
import { AppRoutingModule } from "./app-routing.module";
import { BillingModule } from "./billing/billing.module";
import { CoreModule } from "./core/core.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { SummaryModule } from "./summary/summary.module";
import { SharedModule } from "./shared/shared.module";
import { TicketModule } from "./tickets/ticket.module";
import { InventoryModule } from "./inventory/inventory.module";
import { OrdersModule } from "./orders/orders.module";
import { OrdersTableModule } from "./orders-table/orders.module";
import { GainsightModule } from "./core/gainsight.module";
import { UnusualNetworkMetricsModule } from "./unusual-network-metrics/unusual-network-metrics.module";
import { MetricDetailsModule } from "./metric-details/metric-details.module";

// services
import { StateService } from "./services/state.service";
import { CSVFormatterService } from "./services/csv-formatter.service";

// store
import { environment } from "../environments/environment";
import { clearState } from "./store/reducers/logout.reducer";
import { NonceService } from "./services/nonce.service";

import { googleMeasurementId, initializeAppProviders } from "./app.providers";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { gainsightTagKey } from "./app.config";
import { SingleValueComponent } from './operations-dashboard/widgets/single-value/single-value.component';
import { OperationsComponent } from "./operations-dashboard/operations-dashboard/operations-page.component";
import { OperationsDashboardModule } from './operations-dashboard/operations-dashboard.module';

@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgChartsModule,
    CoreModule,
    BrowserAnimationsModule,
    OperationsDashboardModule,
    DashboardModule,
    SummaryModule,
    SharedModule,
    TicketModule,
    BillingModule,
    InventoryModule,
    OrdersModule,
    OrdersTableModule,
    MomentModule,
    NgxPaginationModule,
    MetricDetailsModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    StoreModule.forRoot(appReducer, {
      metaReducers: [clearState],
    }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    NgxGoogleAnalyticsModule.forRoot(googleMeasurementId),
    GainsightModule.forRoot({ tagKey: gainsightTagKey }),
    UnusualNetworkMetricsModule
  ],
  providers: [
    DatePipe,
    BillingService,
    BrowserService,
    HeaderService,
    TicketService,
    CommentService,
    StateService,
    CSVFormatterService,
    NonceService,
    initializeAppProviders,
  ],
  declarations: [
    AppComponent,
    AppLayoutComponent,
    PageNotFoundComponent,
    PublicLayoutComponent,
    NocPolicyComponent,
    // SingleValueComponent, // TODO: should create its own module
    // OperationsComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject("googleTagManagerCSPNonce") private nonce: string,
  ) {}

  ngDoBootstrap() {
    NgxGoogleAnalyticsModule.forRoot(
      googleMeasurementId,
      [], // initCommands (optional)
      "", // uri (optional)
      environment.production ? false : true, // enableTracing (optional)
      this.nonce
    ),
    NgxGoogleAnalyticsRouterModule
  }
}
