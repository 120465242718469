<section>
  <h1>Create <span class="emphasis">Billing and Payment</span> Ticket</h1>

  <div class="loading-indicator" *ngIf="isLoading">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>

  <form
    [formGroup]="billTicketGroup"
    *ngIf="billTicketGroup"
    [ngClass]="{ 'form-submit-attempt': formSubmitAttempted }"
  >
    <h3 *ngIf="invoices" autocomplete="off">Invoice Date:</h3>
    <mat-form-field *ngIf="invoices">
      <mat-label>Please select</mat-label>
      <mat-select formControlName="invoiceDate" (selectionChange)="canSubmit()">
        <mat-option *ngFor="let invoice of invoices" [value]="invoice.ContentDocument.Title">
          {{ invoice.ContentDocument.Title.slice(0, 10) | date: 'longDate' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="form-error" *ngIf="inlineErrors.invoiceDate">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ inlineErrors.invoiceDate }}</div>
    </div>

    <h3>Issue Summary</h3>
    <mat-form-field class="custom-textarea">
      <mat-label>Please describe your issue...</mat-label>
      <textarea matInput formControlName="summary" rows="5" (keyup)="canSubmit()"></textarea>
    </mat-form-field>
    <div class="form-error" *ngIf="inlineErrors.summary">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ inlineErrors.summary }}</div>
    </div>

    <div class="form-error" *ngIf="submissionError && !errorMessage">
      <div class="custom-icon inline_error"></div>
      <div class="error-message"><b>Error: </b> Unable to submit the ticket. Please try again!</div>
    </div>
    <div class="server-error" *ngIf="submissionError && errorMessage">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ errorMessage }}</div>
    </div>

    <div class="loading-indicator" *ngIf="isSubmitting">
      <mat-spinner [diameter]="25"></mat-spinner>
    </div>

    <button class="button submit-button" (click)="submitTicket()" [disabled]="isLoading || isSubmitting">
      <span>Submit ticket</span>
    </button>
  </form>
</section>
