import type {
  HorizontalBarsKpi,
  SingleValueKPI,
  VerticalBarsKpi,
  GaugeValueKPI,
} from "../types";
import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { NgxGaugeType } from "ngx-gauge/gauge/gauge";
import { ActiveServicesChartService } from "./services/active-services-chart.service";
import { DisconnectedOrdersChartService } from "./services/disconnected-orders-chart.service";
import { InflightOrdersChartService } from "./services/inflight-orders-chart.service";
import { OpenSuportCasesChartService } from "./services/open-support-cases-chart.service";
import { OldOpenSupportCasesChartService } from "./services/old-open-support-cases-chart.service";
import { OpenMaintenanceCasesChartService } from "./services/open-maintenance-cases-chart.service";
import { OpenCareCasesChartService } from "./services/open-care-cases-chart.service";
import { OldOpenCareCasesChartService } from "./services/old-open-care-cases-chart.service";
import { OpenFinanceCasesChartService } from "./services/open-finance-cases-chart.service";
import { OldOpenFinanceCasesChartService } from "./services/old-open-finance-cases-chart.service";
import { YtdNocCasesClosedCodeChartService } from "./services/ytd-noc-cases-closed-code-chart.service";
import { CaseHistoryByDateChartService } from "./services/case-history-by-date-chart.service";

@Component({
  selector: "app-operations-dashboard",
  templateUrl: "./operations-page.component.html",
  styleUrls: ["./operations-page.component.scss"],
  providers: [
    ActiveServicesChartService,
    InflightOrdersChartService,
    DisconnectedOrdersChartService,
    OpenSuportCasesChartService,
    OldOpenSupportCasesChartService,
    OpenMaintenanceCasesChartService,
    OpenCareCasesChartService,
    OldOpenCareCasesChartService,
    OpenFinanceCasesChartService,
    OldOpenFinanceCasesChartService,
    YtdNocCasesClosedCodeChartService,
    CaseHistoryByDateChartService,
  ],
})
export class OperationsComponent {
  public readonly activeServicesChart$ = inject(ActiveServicesChartService).chart$;
  public readonly inflightOrdersChart$ = inject(InflightOrdersChartService).chart$;
  public readonly disconnectedtOrdersChart$ = inject(DisconnectedOrdersChartService).chart$;
  public readonly openSupportCasesChart$ = inject(OpenSuportCasesChartService).chart$;
  public readonly oldOpenSupportCasesChart$ = inject(OldOpenSupportCasesChartService).chart$;
  public readonly openMaintenanceCasesChartService$ = inject(OpenMaintenanceCasesChartService).chart$;
  public readonly openCareCasesChartService$ = inject(OpenCareCasesChartService).chart$;
  public readonly oldOpenCareCasesChartService$ = inject(OldOpenCareCasesChartService).chart$;
  public readonly openFinanceCasesChartService$ = inject(OpenFinanceCasesChartService).chart$;
  public readonly oldOpenFinanceCasesChartService$ = inject(OldOpenFinanceCasesChartService).chart$;
  public readonly ytdNocCasesClosedCodeChartService$ = inject(YtdNocCasesClosedCodeChartService).chart$;
  public readonly caseHistoryByDateChartService$ = inject(CaseHistoryByDateChartService).chart$;

  constructor(private router: Router) {}

  // QUICK TEST
  gaugeType: NgxGaugeType = "semi";
  gaugeValue = 28.3;
  gaugeLabel = "Speed";

  public readonly singleValueKPIs: Record<string, SingleValueKPI> = {
    component1: {
      id: crypto.randomUUID(),
      title: "Component",
      value: 25,
    },
    component2: {
      id: crypto.randomUUID(),
      title: "DPIs",
      value: 91,
      hyperlink: "/billing",
    },
    component3: {
      id: crypto.randomUUID(),
      title: "MIs",
      value: 70,
    },
  };

  public readonly gaugeValueKPI: GaugeValueKPI = {
    id: crypto.randomUUID(),
    title: "Title",
    value: 75, // El valor actual
    maxValue: 100, // El valor máximo
    hyperlink: "/performance-report",
    hyperlinkText: "View Full Report",
  };

  public readonly VerticalBarsKpi: VerticalBarsKpi[] = [
    {
      id: crypto.randomUUID(),
      title: "Pending Orders",
      value: 6,
      secondaryValue: 12,
      label: "Week 1",
      color: "#4caf50",
      secondaryColor: "#ff9800",
      tooltip: "30 orders pending for Week 1",
      hyperlink: "/tickets",
    },
    {
      id: crypto.randomUUID(),
      title: "Pending Orders",
      value: 45,
      secondaryValue: 14,
      label: "Week 2",
      color: "#ff9800",
      secondaryColor: "#ff9800",
      tooltip: "45 orders pending for Week 2",
      hyperlink: "/tickets",
    },
    {
      id: crypto.randomUUID(),
      title: "Pending Orders",
      value: 30,
      secondaryValue: 40,
      label: "Week 3",
      color: "#f44336",
      secondaryColor: "#ff9800",
      tooltip: "20 orders pending for Week 3",
      hyperlink: "/tickets",
    },
  ];

  public readonly horizontalBarsKpi: HorizontalBarsKpi = {
    id: crypto.randomUUID(),
    title: "My Title",
    value: Array.from({ length: 100 }, (_, index) => {
      return {
        label: "value " + (index + 1),
        value: Math.trunc(Math.random() * 100),
        link: "https://www.google.com?" + index,
      };
    }),
    /* value: [
      {
        label: 'Test Label',
        value: 5,
      }
    ], */
  };

  public readonly total: number = this.VerticalBarsKpi.reduce(
    (acc, kpi) => acc + kpi.value,
    0
  );
  public readonly loading: boolean = false;

  public handleNavigation(link: string): void {
    this.router.navigate([link]);
  }
}
