import { computed, inject, Injectable, signal } from '@angular/core';
import { HorizontalBarsKpi } from '../../../types';
import { chartDefaults } from '../defaults';
import type { ChartType, ChartEvent } from 'chart.js';
import { Router } from '@angular/router';

@Injectable()
export class ChartService {
  public readonly lineChartType: ChartType = 'line';

  public readonly lineChartPlugins = chartDefaults.plugins();
  public readonly lineChartOptions = chartDefaults.options();

  public readonly data$ = signal<HorizontalBarsKpi>(null!);


  public readonly lineChartData$ = computed(() => {
    const lineChartData = chartDefaults.data();

    lineChartData.labels = [];
    lineChartData.datasets[0].data = [];
    lineChartData.datasets[0].label = 'Record Count';

    this.data$()?.value.forEach((item) => {
      lineChartData.labels!.push(item.label);
      lineChartData.datasets[0].data.push(item.value);
    });

    return lineChartData;
  });

  private readonly router$ = inject(Router);
  public chartClicked(event: { event?: ChartEvent; active?: object[] }): void {
    if (!event.active?.length || event.active?.[0]?.['index'] === undefined) {
      return;
    }

    const clickedIndex = event.active[0]['index'];
    const link = this.data$()?.value[clickedIndex]?.link;

  }
}
