<section class="dashboard-page">
  <div class="container">
    <div class="container-top">
      <div class="container-title">
        <h1>Operations Dashboard <span class="badge">new</span></h1>
      </div>

      <div class="kpi-flex">
        <div class="kpi-grid-2x">
          <ng-container *ngIf="activeServicesChart$ | async as chart">
            <app-single-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
            ></app-single-value>
          </ng-container>

          <ng-container *ngIf="inflightOrdersChart$ | async as chart">
            <app-single-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
            ></app-single-value>
          </ng-container>

          <ng-container *ngIf="disconnectedtOrdersChart$ | async as chart">
            <app-single-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
            ></app-single-value>
          </ng-container>

          <ng-container *ngIf="openSupportCasesChart$ | async as chart">
            <app-single-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
            ></app-single-value>
          </ng-container>
          <ng-container *ngIf="oldOpenSupportCasesChart$ | async as chart">
            <app-single-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
            ></app-single-value>
          </ng-container>
          <ng-container
            *ngIf="openMaintenanceCasesChartService$ | async as chart"
          >
            <app-single-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
            ></app-single-value>
          </ng-container>
          <ng-container *ngIf="openCareCasesChartService$ | async as chart">
            <app-single-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
            ></app-single-value>
          </ng-container>
          <ng-container *ngIf="oldOpenCareCasesChartService$ | async as chart">
            <app-single-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
            ></app-single-value>
          </ng-container>

          <ng-container *ngIf="openFinanceCasesChartService$ | async as chart">
            <app-single-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
            ></app-single-value>
          </ng-container>
        </div>
        <div class="kpi-column"></div>
        <!-- <div class="kpi-flex-gauge">
          <app-gauge-value [data]="gaugeValueKPI"></app-gauge-value>
          <app-gauge-value [data]="gaugeValueKPI"></app-gauge-value>
        </div> -->
      </div>

      <ng-container *ngIf="oldOpenFinanceCasesChartService$ | async as chart">
        <app-single-value
          class="stat__item"
          [ngClass]="chart.ngClass"
          [data]="chart.data"
        ></app-single-value>
      </ng-container>

      <div class="charts">
        <ng-container *ngIf="caseHistoryByDateChartService$ | async as chart">
          <app-line-values
            [data]="chart.data"
            [loading]="chart.loading"
            (navigate)="handleNavigation($event)"
          ></app-line-values>
        </ng-container>
      </div>

      <div class="charts horizontal-bars">
        <ng-container
          *ngIf="ytdNocCasesClosedCodeChartService$ | async as chart"
        >
          <app-horizontal-bars
            [data]="chart.data"
            [loading]="chart.loading"
            (navigate)="handleNavigation($event)"
          ></app-horizontal-bars>
        </ng-container>
        <!-- <app-horizontal-bars
          [data]="horizontalBarsKpi"
          [total]="total"
          [loading]="loading"
          (navigate)="handleNavigation($event)"
        ></app-horizontal-bars> -->
      </div>

      <!-- <div class="charts">
        <app-vertical-bars
          [data]="VerticalBarsKpi"
          [total]="total"
          [loading]="loading"
          (navigate)="handleNavigation($event)"
        ></app-vertical-bars>
      </div> -->
    </div>
  </div>
</section>
