<div class="single-value-modal">
    <div class="single-value-modal__header">
        <h1 class="single-value-modal__title">
            {{ data.title }}
        </h1>
        <button mat-icon-button class="single-value-modal__close" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="single-value-modal__body">
        {{ data.value }}
    </div>

    <div class="single-value-modal__footer">
        <a [routerLink]="data.hyperlink">
            {{ data.hyperlinkText || ("View Report (" + data.title + ")") }}
        </a>
    </div>
</div>
