import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { ContentDocumentLink } from 'src/app/models/content-document-link.model';
import { MatSelectChange } from '@angular/material/select';
import { Payment } from '../../../models/payment.model';

@Component({
  selector: 'app-billing-list',
  templateUrl: './billing-list.component.html',
  styleUrls: ['../../../../styles/parent-list.scss', './billing-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingListComponent {
  @Input() payments: Payment[];
  @Input() isPaymentsLoading: boolean;
  @Input() isPaymentsLoaded: boolean;

  @Input() invoices: ContentDocumentLink[];
  @Input() isInvoicesLoading: boolean;
  @Input() isInvoicesLoaded: boolean;
  @Input() selectedInvoice: ContentDocumentLink;

  @Input() browserSize: any;

  @Output() onSelectInvoice: EventEmitter<any> = new EventEmitter();
  @Output() downloadInvoice: EventEmitter<any> = new EventEmitter();
  @Output() changeInvoiceFilterSelection: EventEmitter<any> = new EventEmitter();
  @Output() changePaymentFilterSelection: EventEmitter<any> = new EventEmitter();

  filters = [
    { text: 'LAST 6', value: 6 },
    { text: 'LAST 12', value: 12 },
    { text: 'LAST 24', value: 24 }
  ];
  paymentSelectedFilter = '6';
  invoiceSelectedFilter = '6';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  onSelect($event: ContentDocumentLink) {
    this.onSelectInvoice.emit($event);
    this.changeDetectorRef.detectChanges();
  }

  onDownload($event: ContentDocumentLink) {
    this.downloadInvoice.emit($event);
  }

  onInvoiceFilterSelect(event: MatSelectChange) {
    const size: number = event.value;
    this.changeInvoiceFilterSelection.emit(size);
  }

  onPaymentFilterSelect(event: MatSelectChange) {
    const size: number = event.value;
    this.changePaymentFilterSelection.emit(size);
  }
}
