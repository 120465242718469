import { computed, inject, Injectable, signal } from "@angular/core";
import { HorizontalBarsKpi } from "../../../types";
import { chartDefaults } from "../defaults";
import type { ChartType, ChartEvent } from "chart.js";
import { Router } from "@angular/router";

@Injectable()
export class ChartService {
  public readonly barChartType: ChartType = "bar";
  public readonly barChartPlugins = chartDefaults.plugins();
  public readonly barChartOptions = chartDefaults.options();
  public readonly data$ = signal<HorizontalBarsKpi>(null!);
  public readonly recommendedHeight$ = computed(() => {
    const minHeigth = 380;
    return Math.max(minHeigth, (this.data$()?.value?.length || 0) * 30);
  });

  public readonly barChartData$ = computed(() => {
    const barChartData = chartDefaults.data();

    const blueColor = "rgba(54, 162, 235, 0.8)";
    this.data$()?.value.forEach((item) => {
      barChartData.labels!.push(item.label);
      barChartData.datasets[0].data.push(item.value);
    });

    barChartData.datasets[0].backgroundColor = blueColor;
    barChartData.datasets[0].borderColor = "transparent";
    barChartData.datasets[0].borderWidth = 0;

    return barChartData;
  });

  private readonly router$ = inject(Router);
  public chartClicked(event: { event?: ChartEvent; active?: object[] }): void {
    if (!event.active?.length || event.active?.[0]?.["index"] === undefined) {
      return;
    }

    const clickedIndex = event.active[0]["index"];
    const link = this.data$()?.value[clickedIndex]?.link;

  }
}
