import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

// angular materials
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from "@angular/material/select";
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';

// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { effects, ordersReducers } from 'src/app/store';

import { SharedModule } from '../shared/shared.module';
import { OrdersParserService } from '../services/orders-parser.service';

import { OrdersComponent } from './orders.component';
import { OrderTableComponent } from './table/order-table.component';
import { ExtendedRowComponent } from './table/extended-row/extended-row.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { OrderDetailsBoxComponent } from './order-details-box/order-details-box.component';
import { TablePageComponent } from './table-page/table-page.component';
import { OrderContactsBoxComponent } from './order-contacts-box/order-contacts-box.component';
import { OrderDatesBoxComponent } from './order-dates-box/order-dates-box.component';

@NgModule({
	imports: [
		BrowserAnimationsModule,
		CommonModule,
		SharedModule,

		MatDialogModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatSelectModule,
		MatGridListModule,
		MatIconModule,

		StoreModule.forFeature('orders', ordersReducers),
		EffectsModule.forFeature(effects),
	],
	providers: [
		OrdersParserService,
	],
	declarations: [
		OrdersComponent,
		OrderTableComponent,
		ExtendedRowComponent,
		ViewOrderComponent,
		OrderDetailsBoxComponent,
		TablePageComponent,
		OrderContactsBoxComponent,
		OrderDatesBoxComponent,
	],
})
export class OrdersTableModule { }
