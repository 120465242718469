<div *ngIf="formRef" [formGroup]="formRef">
  <h3>Primary Reporter</h3>
  <div class="loading-indicator" *ngIf="isLoadingReporters">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <mat-form-field *ngIf="primaryReporter">
    <mat-label>Primary Reporter</mat-label>
    <mat-select formControlName="reporter" (selectionChange)="primaryReporterChanged($event)">
      <mat-option class="reporter_option" *ngFor="let rep of reporters" [value]="rep.Id">
        {{ rep.Name | titlecase }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <app-radio-select
    *ngIf="primaryReporter"
    [formRef]="formRef"
    [reporter]="primaryReporter"
    [altContact]="altContact"
    [inlineErrors]="inlineErrors"
    (changeContact)="canSubmit()"
  ></app-radio-select>
</div>
