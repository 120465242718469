import {
  getActiveOlis,
  LoadOrders,
  LoadOrdersFail,
  LoadOrdersSuccess,
} from "../../../store";
import { ChartService } from "./chart.service";
import { SingleValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";

@Injectable()
export class InflightOrdersChartService extends ChartService<SingleValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadOrders,
    success: LoadOrdersSuccess,
    failure: LoadOrdersFail,
  }).pipe(
    map(({ loading, data }) => {
      let dataValue: number = getActiveOlis(data || []).length || 0;

      let colorClass = "";
      if (dataValue > 50) {
        colorClass = "chart-text-color--danger";
      } else if (dataValue > 25) {
        colorClass = "chart-text-color--warn";
      }

      return {
        ngClass: [colorClass],
        loading,
        data: {
          id: "Inflight Orders",
          title: "Inflight Orders",
          value: dataValue,
          hyperlink: '/orders' // need flags
        },
      };
    })
  );
}
