<div class="line-values-item stats">
  <div class="line-values-item__header">
    <h1 class="line-values-item__title">
      {{ data$()?.title }}
    </h1>

    <div>
      <button
        mat-icon-button
        class="line-values-item__expand"
        (click)="downloadXlsx()"
      >
        <mat-icon>file_open</mat-icon>
      </button>
      <button
        mat-icon-button
        class="line-values-item__expand"
        (click)="downloadImage()"
      >
        <mat-icon>arrow_downward</mat-icon>
      </button>
      <button
        mat-icon-button
        class="line-values-item__expand"
        (click)="openModal()"
      >
        <mat-icon>fullscreen</mat-icon>
      </button>
    </div>
  </div>
  <div class="line-values-item__body">
    <section class="card">
      <div class="widget-container">
        <div class="loading-spinner" *ngIf="loading; else Chart">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>

        <ng-template #Chart>
          <div class="line-values-item__chart">
            <div class="chart-container">
              <canvas
                baseChart
                [type]="chartService$.lineChartType"
                [data]="chartService$.lineChartData$()"
                [options]="chartService$.lineChartOptions"
                [plugins]="chartService$.lineChartPlugins"
                (chartClick)="chartService$.chartClicked($event)"
              ></canvas>
            </div>
          </div>
        </ng-template>
      </div>
    </section>
  </div>

  <!-- <div class="line-values-item__footer">
      <a [routerLink]="data$()?.hyperlink">
        {{ data$()?.hyperlinkText || "View Report (" + data$()?.title + ")" }}
      </a>
    </div> -->
</div>
