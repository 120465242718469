<div class="loading-indicator" *ngIf="isLoadingServices">
  <mat-spinner [diameter]="30"></mat-spinner>
</div>

<div class="service-info-container" [formGroup]="formRef" *ngIf="formRef && allServices.length > 0">
  <div class="question" *ngIf="allServices.length > 1">Identify issue by:</div>

  <mat-radio-group
    class="default-contact-options"
    formControlName="identifyIssueBy"
    (change)="onRadioChange()"
    *ngIf="allServices.length > 1"
  >
    <mat-radio-button class="service-location-radio" value="location" checked>
      <div class="icon-text">
        <div class="custom-icon location"></div>
        <div class="custom-icon location_dark"></div>
        Service Location
      </div>
    </mat-radio-button>
    <mat-radio-button class="service-location-radio" value="service">
      <div class="icon-text">
        <div class="custom-icon circuit"></div>
        <div class="custom-icon circuit_dark"></div>
        Service
      </div>
    </mat-radio-button>
  </mat-radio-group>
  <div *ngIf="this.formRef.value.identifyIssueBy === LOCATION; else serviceBlock">
    <!---
    - if there is one location, there is only one service: display both static
    - if there is one location and multiple services: display location static and services as a dropdown
    - if there are many locations: display location dropdown and hide service information
    ---- choosing a location with one service: display service static
    ---- choosing a location with many services: display service dropdown -->
    <div *ngIf="uniqueLocations.length === 1">
      <!-- <div class="display-static">
        <h3>Service location:</h3>
        <div class="icon-text">
          <div class="custom-icon location_dark"></div>
          {{ uniqueLocations[0].Z_Location__r.Name }}
        </div>
      </div> -->

      <div *ngIf="allServices.length === 1">
        <div class="display-static">
          <h3>Service:</h3>
          <div class="icon-text">
            <div class="custom-icon circuit_dark"></div>
            {{ allServices[0].Name }} – {{ allServices[0].RecordType.Name }}
          </div>
        </div>
      </div>
      <div *ngIf="allServices.length > 1">
        <div class="question">Which service?</div>
        <mat-form-field *ngIf="allServices.length > 0">
          <mat-label>Please select a service</mat-label>
          <mat-select formControlName="service" (valueChange)="onServiceSelected($event)">
            <mat-option *ngFor="let s of allServices" [value]="s.Id">
              {{ s.Name }} – {{ s.RecordType.Name }} <span *ngIf="s.bandwidth"> - {{ s.bandwidth }}</span
              ><span *ngIf="s?.Vendor__r?.Display_Alias__c"> - {{ s?.Vendor__r?.Display_Alias__c }} </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="uniqueLocations.length > 1" class="select-container">
      <app-autocomplete-location
        [allLocations]="uniqueLocations"
        (locationSelected)="locationSelected($event)"
      ></app-autocomplete-location>
      <div *ngIf="servicesAtChosenLocation.length === 1" class="display-static">
        <h3>Service associated:</h3>
        <div class="icon-text">
          <div class="custom-icon circuit_dark upper"></div>
          <ng-container *ngIf="servicesAtChosenLocation[0]">
            {{ servicesAtChosenLocation[0].Name ? servicesAtChosenLocation[0].Name : '' }} -
            {{ servicesAtChosenLocation[0].RecordType ? servicesAtChosenLocation[0].RecordType.Name : '' }}
          </ng-container>
        </div>
      </div>
      <div *ngIf="servicesAtChosenLocation.length > 1">
        <div class="question">Which service?</div>
        <mat-form-field>
          <mat-label>Please select a service</mat-label>
          <mat-select formControlName="service" (valueChange)="onServiceSelected($event)">
            <mat-option *ngFor="let s of servicesAtChosenLocation" [value]="s.Id">
              {{ s.Name }} – {{ s.RecordType.Name }}
              <span *ngIf="s.Bandwidth_Text__c"> - {{ s.Bandwidth_Text__c }}</span
              ><span *ngIf="s?.Vendor__r?.Display_Alias__c"> - {{ s?.Vendor__r?.Display_Alias__c }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-error" *ngIf="inlineErrors.service">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ inlineErrors.service }}</div>
    </div>
  </div>
  <ng-template #serviceBlock>
    <div>
      <!--
      - if there is one service, there is only one location: display both static
      - if there are multiple services: display services in a dropdown
      ---- after choosing a service, display the location static -->
      <div *ngIf="allServices.length === 1">
        <div class="question">Service:</div>
        {{ allServices[0].Name }} - {{ allServices[0].RecordType.Name }}
        <div class="question">Service location:</div>
        {{ allServices[0].Z_Location__r.Name }} – {{ allServices[0].RecordType.Name }}
      </div>

      <div *ngIf="allServices.length > 1" class="select-container">
        <app-autocomplete
          [allServices]="allServices"
          [selectedServiceId]="selectedServiceId"
          [ticketType]="ticketType"
          [formRef]="formRef"
          [identifyIssueBy]="this.formRef.value.identifyIssueBy"
          (serviceSelected)="serviceSelected($event)"
        ></app-autocomplete>
        <!-- <div *ngIf="chosenService" class="display-static">
          <h3>Service location:</h3>
          <div class="icon-text">
            <div class="custom-icon location_dark"></div>
            {{ chosenService.Z_Location__r ? chosenService.Z_Location__r.Name : '' }}
          </div>
        </div> -->
      </div>
      <div class="form-error" *ngIf="inlineErrors.service">
        <div class="custom-icon inline_error"></div>
        <div class="error-message">{{ inlineErrors.service }}</div>
      </div>
    </div>
  </ng-template>
</div>
