import { ChartConfiguration, ChartData } from "chart.js";
import DataLabelsPlugin from "chartjs-plugin-datalabels";

//#region     BEGIN OPTIONS
const defaultChartOptions = (): ChartConfiguration["options"] => ({
  indexAxis: "y",
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: true,
      },
      position: 'top',
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: false,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      anchor: "end",
      align: "end",
      font: {
        size: 12,
        weight: "bolder",
        family: '"open-sans", sans-serif',
      },
      color: "#000",
      formatter: (value) => {
        return value;
      },
    },
  },
});
//#endregion  END OPTIONS

//#region     BEGIN DATA
const defaultChartData = (): ChartData<'bar'> => ({
  labels: [],
  datasets: [{
    data: [],
    label: '',
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(201, 203, 207, 0.2)'
    ],
    borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)'
    ],
    borderWidth: 1
  }],
});
//#endregion  BEGIN DATA

//#region     BEGIN DATA
const defaultChartPlugins = (): any[] => [DataLabelsPlugin];
//#endregion  BEGIN DATA

export const chartDefaults = Object.freeze({
  plugins: defaultChartPlugins,
  options: defaultChartOptions,
  data: defaultChartData,
});
