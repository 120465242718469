<div class="single-value-item stats">
  <div class="single-value-item__header" >
    <h1 class="single-value-item__title">
      {{ data.title }}
    </h1>
    <div>
      <button
        mat-icon-button
        class="single-value-item__expand"
        (click)="downloadImage()"
      >
        <mat-icon>arrow_downward</mat-icon>
      </button>
      <button
        mat-icon-button
        class="single-value-item__expand"
        (click)="openModal()"
      >
        <mat-icon>fullscreen</mat-icon>
      </button>
    </div>
  </div>
  <div class="single-value-item__body" [appTooltip]="tooltipTemplate" #modalContent>
    {{ data.value }}
  </div>

  <div class="single-value-item__footer">
    <a [routerLink]="data.hyperlink">
      {{ data.hyperlinkText || "View Report (" + data.title + ")" }}
    </a>
  </div>
</div>

<ng-template #tooltipTemplate>
  <div>
    <strong>Record Count: {{ data.value }}</strong>
  </div>
</ng-template>
