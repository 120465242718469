import { NgModule } from "@angular/core";

/*  combined common modules into one file */
import { RouterModule } from "@angular/router";
import { StoreModule } from "@ngrx/store";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { InlineSVGModule } from "ng-inline-svg-2";

/*  angular material  */
import { MatNativeDateModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SharedModule } from "../shared/shared.module";

// components & containers
import * as fromComponents from "./components";
import * as fromContainers from "./containers";
import { billingReducers } from "../store";
import { GainsightModule } from "../core/gainsight.module";

@NgModule({
    declarations: [...fromComponents.components, ...fromContainers.containers],
    exports: [RouterModule],
    imports: [
        SharedModule,
        RouterModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatRadioModule,
        MatButtonModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        PdfViewerModule,
        InlineSVGModule.forRoot(),
        StoreModule.forFeature("billing", billingReducers),
        GainsightModule
    ]
})
export class BillingModule {}
