import { SingleValueModalComponent } from "./single-value-modal/single-value-modal.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Component, inject, Input, ElementRef, ViewChild } from "@angular/core";
import { SingleValueKPI } from "../../types";
import * as domtoimage from "dom-to-image-more";

@Component({
  selector: "app-single-value",
  templateUrl: "./single-value.component.html",
  styleUrls: ["./single-value.component.scss"],
})
export class SingleValueComponent {
  @Input({ required: true }) data: SingleValueKPI;
  private readonly matDialog = inject(MatDialog);
  @ViewChild("modalContent") modalContent: ElementRef | undefined;

  public downloadImage(): void {
    const contentElement = this.modalContent?.nativeElement;

    if (contentElement) {
      const options = {
        width: contentElement.offsetWidth,
        height: contentElement.offsetHeight,
        scale: 1.5,
        style: {
          transform: "scale(1)",
          transformOrigin: "top center",
        },
      };

      domtoimage
        .toPng(contentElement, options)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "component-image.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error", error);
        });
    }
  }

  public openModal(): void {
    this.matDialog.open<SingleValueModalComponent, SingleValueKPI>(
      SingleValueModalComponent,
      {
        width: "calc(100vw - 1rem)",
        hasBackdrop: true,
        data: this.data,
      }
    );
  }
}
